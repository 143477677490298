import React, { lazy, Suspense, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../language/i18n";
import MainLayout from "../layouts/MainLayout";

// Lazy loading van pagina's
const Home = lazy(() => import("../pages/Home"));
const AboutUs = lazy(() => import("../pages/About"));
const Committees = lazy(() => import("../pages/Committees"));
const Contact = lazy(() => import("../pages/Contact"));
const CookiesPolicy = lazy(() => import("../pages/CookiesPolicy"));
const DeafHistoryInternational = lazy(() =>
  import("../pages/DeafHistoryInternational")
);
const Gallery = lazy(() => import("../pages/Gallery"));
const Info = lazy(() => import("../pages/Info"));
const Location = lazy(() => import("../pages/Location"));
const PrivacyPolicy = lazy(() => import("../pages/PrivacyPolicy"));
const ProgramAndSpeakers = lazy(() => import("../pages/ProgramAndSpeakers"));
const Registration = lazy(() => import("../pages/Registration"));
const TermsAndConditions = lazy(() => import("../pages/TermsAndConditions"));
const NotFound = lazy(() => import("../pages/NotFound"));

const VALID_LANGUAGES = ["en", "nl", "fr"];

export const ROUTES = (t) => {
  return {
    home: {
      path: "home",
      title: t("navigation.home"),
    },
    about: {
      path: "about-us",
      title: t("navigation.about-us"),
    },
    committees: {
      path: "committees",
      title: t("navigation.committees"),
    },
    contact: {
      path: "contact",
      title: t("navigation.contact"),
    },
    cookiesPolicy: {
      path: "cookies-policy",
      title: t("navigation.cookies-policy"),
    },
    deafHistoryInternational: {
      path: "deaf-history-international",
      title: t("navigation.deaf-history-international"),
    },
    gallery: {
      path: "gallery",
      title: t("navigation.gallery"),
    },
    info: {
      path: "info",
      title: t("navigation.info"),
    },
    location: {
      path: "location",
      title: t("navigation.location"),
    },
    privacyPolicy: {
      path: "privacy-policy",
      title: t("navigation.privacy-policy"),
    },
    programAndSpeakers: {
      path: "program-and-speakers",
      title: t("navigation.program-and-speakers"),
    },
    registration: {
      path: "registration",
      title: t("navigation.registration"),
    },
    termsAndConditions: {
      path: "terms-and-conditions",
      title: t("navigation.terms-and-conditions"),
    },
  };
};

export default function AppRoutes() {
  const { t } = useTranslation();
  const location = useLocation();
  const lng = location.pathname.split("/")[1];

  const isValidLng = VALID_LANGUAGES.includes(lng);

  useEffect(() => {
    if (isValidLng && i18n.language !== lng) {
      i18n.changeLanguage(lng);
    }
  }, [lng, isValidLng]);

  const routes = ROUTES(t);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        {isValidLng && (
          <Route path="/:lng" element={<MainLayout />}>
            <Route path={routes.home.path} element={<Home />} />
            <Route path={routes.about.path} element={<AboutUs />} />
            <Route path={routes.committees.path} element={<Committees />} />
            <Route path={routes.contact.path} element={<Contact />} />
            <Route
              path={routes.cookiesPolicy.path}
              element={<CookiesPolicy />}
            />
            <Route
              path={routes.deafHistoryInternational.path}
              element={<DeafHistoryInternational />}
            />
            <Route path={routes.gallery.path} element={<Gallery />} />
            <Route path={routes.info.path} element={<Info />} />
            <Route path={routes.location.path} element={<Location />} />
            <Route
              path={routes.privacyPolicy.path}
              element={<PrivacyPolicy />}
            />
            <Route
              path={routes.programAndSpeakers.path}
              element={<ProgramAndSpeakers />}
            />
            <Route path={routes.registration.path} element={<Registration />} />
            <Route
              path={routes.termsAndConditions.path}
              element={<TermsAndConditions />}
            />
            <Route path="*" element={<NotFound />} />
          </Route>
        )}
        <Route path="/" element={<Navigate to="/en/home" />} />
        {!isValidLng && <Route path="*" element={<NotFound />} />}
      </Routes>
    </Suspense>
  );
}
